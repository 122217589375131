import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Head from './components/Head';
import Foot from './components/Foot';
import Home from './pages/Home';
import Privacy from './pages/Privacy';
// import Project from './pages/Project';
import Contact from './pages/Contact';
import Term from './pages/Term';
import Txr from './pages/Txr';
import Dinexr from './pages/Dinexr';
import Wxr from './pages/Wxr';
import Drivexr from './pages/Drivexr';
import Pxr from './pages/Pxr';
import ReactGA from 'react-ga';

function App() {
  ReactGA.initialize('G-LEQX7J78NQ');

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === 'u') {
        event.preventDefault();
        alert("Please don't use Ctrl+U");
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <>
      <BrowserRouter>
        <Head />
        <Routes>
          <Route>
            <Route path='/' exact element={<Home />} />
            {/* <Route path='/projects' element={<Project />}></Route> */}
            <Route path='/txr' element={<Txr />}></Route>
            <Route path='/dinexr' element={<Dinexr />}></Route>
            <Route path='/wxr' element={<Wxr />}></Route>
            <Route path='/drivexr' element={<Drivexr />}></Route>
            <Route path='/pxr' element={<Pxr />}></Route>
            <Route path='/contact' element={<Contact />}></Route>
            <Route path='/privacy' element={<Privacy />}></Route>
            <Route path='/terms' element={<Term />}></Route>
            <Route path='/*' element={<Navigate to='/' />}></Route>
          </Route>
        </Routes>
        <Foot />
      </BrowserRouter>
    </>
  );
}

export default App;
