import React, { useEffect } from 'react';
import '../Contact.css';
import {Link} from 'react-router-dom';
import { Facebook, Instagram, Linkedin, MailCheck, Phone, Twitter } from 'lucide-react'
import { FaDiscord, FaWhatsapp } from "react-icons/fa";

const Contact = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <section className='sec-cont-1 sec-color py-5'>
                <div className="container-fluid" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-out-cubic">
                    <div className="row align-items-center mt-5">
                        <div className="col-md-7">
                            <img src={`assets/Back1.webp`} alt="" className='rounded-5 w-100' />
                        </div>
                        <div className="col-md-5" style={{ "marginLeft": "-40px" }}>
                            <div className="bg-center " style={{ "padding": "140px 40px " }}>
                                <h1 className='text-white fw-bolder w-75 mb-5'>REACH OUT TO US AT : //</h1>
                                <ul class="list-group">
                                    <Link onClick={() => window.location = 'mailto: info@interioXr.com'}><li class="list-group-item border-0 bg-transparent text-white pb-1 fs-4"><MailCheck /> info@interioxr.com</li></Link>
                                    <Link onClick={() => window.location = 'tel: +918826144224'} ><li class="list-group-item border-0 bg-transparent text-white fs-4 pb-1"><Phone /> +918826144224</li></Link>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="sec-cont-2 my-5" >
                <div className="container" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-out-cubic">
                    <div className="row">
                        <div className="col-md-7 offset-md-5 p-3 sec-color " id='bgg'>
                            <h1 className='text-white fw-bolder'>OR SCHEDULE A WORD!</h1>
                        </div>
                    </div>
                </div>
            </section>

            <Link to='https://www.facebook.com/interioxr'><button className='btn face fs-3 text-primary pb-3'><Facebook /></button></Link>
            <Link to='https://api.whatsapp.com/send/?phone=918826144224&text&type=phone_number&app_absent=0' target='_blank'><button className='btn whats fs-4 text-success pb-3'><FaWhatsapp  /></button></Link>
            <Link to='https://www.linkedin.com/company/interioxr/'><button className='btn link fs-3 text-primary pb-3'><Linkedin /></button></Link>
            <Link to='https://www.instagram.com/interioxr/' target='_blank'><button className='btn insta fs-3 text-danger pb-3'><Instagram /></button></Link>
            <Link to='https://twitter.com/InterioXr'><button className='btn twit fs-3 text-primary pb-3'><Twitter /></button></Link>
            <Link to='https://discord.gg/z8h5BTUc' target='_blank'><button className='btn discord fs-3 text-white pb-3'><FaDiscord /></button></Link>
        </>
    )
}

export default Contact;