import React, { useEffect } from 'react';
import { ChevronsRight } from 'lucide-react';
import { Link } from 'react-router-dom';

const Privacy = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <section className='privacy py-5' style={{ 'backgroundColor': '#0b1321' }}>
                <div className="container">
                    <h1 className="text-light text-shadow1 mt-5 mb-2 text-center">PRIVACY POLICY</h1>
                    <p className='text-light text-center mb-3'>Updated as on 12th January 2024</p>
                    <div className="butt text-center">
                        <Link className='btn btn-primary rounded-pill shadow-lg mb-5 ' to='https://docs.google.com/document/d/e/2PACX-1vRaEMWkd2q4gCcYCNrgsVXwSqEHrJ-6ELNoafYoHmW5lVDbJCBcK8GJnxB_fNsqG2qaQbM1DoZNgedC/pub' target='_blank'>View Google Docs</Link>
                    </div>
                    <p className='text-white'>InterioXr Technologies Private Limited (hereinafter referred to as “iXr”), and its platform InteriorVerse (hereinafter referred to as “iVr”) is committed to safeguarding its users’ privacy (hereinafter referred to as “You”) accessing its apps & websites (hereinafter referred to as “App & Web platforms”) and has provided this privacy policy (hereinafter referred to as “Privacy Policy”) to familiarise the user with the manner in which InterioXr collects, uses and discloses their information collected through the App & Web platforms. Before you subscribe to and/or begin participating in or using the App & Web platforms, you agree that you have fully read, understood, and accept the terms and conditions of this Privacy Policy.
                    </p>
                    <p className='text-white'>Wherever the business entity iXr is mentioned within the text, it is of safe assumption that the terms extend to the iVr platform as its web & app offering. The Policy applies to all information that iXr has about you and your account. If you do not wish to be bound by the terms and conditions of this Privacy Policy, you may not proceed further to use the App & Web platforms. This Policy should be at all times read along with the Terms of Use & Service of the platform, available here.
                    </p>
                    <p className='text-white'>This Privacy Policy is published in accordance with the provisions of Rule 3(1) of the Information Technology (Intermediaries Guidelines) Rules, 2011 that require publishing the rules and regulations, the privacy policy for access or usage of the platform.
                    </p>
                    <h3 className="text-light text-shadow1 mt-5">1. Collection of Information</h3>
                    <ul class="list-group">
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' />iXr collects information, including sensitive personal data and information (hereinafter referred to as “Information”), from the users, when they register to gain access to the services provided by iXr, or registration in embedded APIs, or at other specific instances when iXr deems it necessary and requests users to provide the Information.</li>
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' /> For individuals, Information shall include, but not be limited to, the following: <br />
                            Name of User; Date of Birth; Gender; Address; Geo-Location Details; Telephone Number; E-Mail; Address; Password(s); Financial Information such as et. al. Bank Account, Credit Card, Debit Card or other Payment Instrument Details; Social Media Details.</li>
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' />For business entities, Information shall include, but not be limited to, the following:<br />
                            Name of User; Name of Business Entity; GST Number; CIN; Address; Geo-Location Details; Telephone Number; E-Mail Address; Password(s); Financial Information such as et. al. Bank Account, Credit Card, Debit Card, or other Payment Instrument Details; Social Media Details.</li>
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' /> iXr’s primary goal in collecting information is to provide the user with customised experiences on our App & Web platforms. This includes personalised services, interactive communication, and other services. iXr collects your Personal Information in order to record, support, and facilitate your participation in the activities you select, track your preferences, to notify you of any updated information and new activities and other related functions offered by iXr, keep you informed about the latest content available on the App & Web platforms, special offers, and other products and services of iXr, to provide you with a customised online experience, to assist you with customer service or technical support issues, to follow up with you after your visit, to otherwise support your relationship with iXr, or to prevent fraud and unlawful use.</li>
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' /> Certain information may be collected when you visit the App & Web platforms and such information may be stored in server logs in the form of data. Through this data, iXr understands the use and number of users visiting the App & Web platforms. Some or all data collected may be shared with the sponsors, investors, advertisers, developers, strategic business partners of iXr. While using the App & Web platforms, iXr’s servers (hosted by themselves, or by third-party service providers) may collect information indirectly and automatically about your activities on the platforms, for instance by way of cookies.</li>
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' />Promotions that run on our platforms may be sponsored by companies other than iXr or may be co-sponsored by iXr and another company. We use third-party service providers to serve ads on our behalf across the internet and sometimes on the platforms. They may collect information about your visits to our platform. iXr uses the log files which include but not limited to internet protocol (IP) addresses, browser software, number of clicks, number of unique visitors, internet service provider, exit/referring pages, type of platform, date/time stamp, screen resolution, etc. for analysis that helps us provide you improved user experience and services.</li>
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' />We record the buying and browsing activities of our users including but not limited to your contact details and profiles and use the same to provide value-added services to our users.</li>
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' />We use them to administer the site, track a user’s movement, and gather broad demographic information for aggregate use. Once a user registers, he/she is no longer anonymous to iXr and it is deemed that you have given iXr the right to use the personal & non-personal information.</li>
                    </ul>
                    <h3 className="text-light text-shadow1 mt-5">2. Use of Information</h3>
                    <ul class="list-group">
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' />Business information is used to display the user’s business listing or product offerings across our platform to fetch maximum business opportunities for the user. If you upload any content on the platform, the same may be available to the other users of the platform. iXr will not be liable for the disclosure and dissemination of such content to any third-parties. Once the user’s content is displayed on our platform, the user may start receiving business enquiries through email, phone calls, or SMS notifications, from third-parties that might or might not be of their interest, iXr does not exercise any control over it.</li>
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' /> iXr may, if you so choose, send direct advertisement mailers to you at the address given by you which could contain details of the products or services displayed on iXr or of any third-party not associated with iXr. You have the option to opt-out of this direct or third-party mailer by clicking on the unsubscribe link option attached to the e-mail. iXr respects your privacy and if you do not want to receive e-mail or other communications from iXr.
                        </li>
                    </ul>
                    <h3 className="text-light text-shadow1 mt-5">3. Disclosure of Information</h3>
                    <p className='text-white'>
                        In situations when iXr is legally obligated to disclose information to the government or other third parties, iXr will do so.
                    </p>
                    <h3 className="text-light text-shadow1 mt-5">4. Sharing of Information</h3>
                    <ul class="list-group">
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' />As a general rule, iXr does not disclose or share any of the user’s personally identifiable information except when iXr has the user’s permission or under special circumstances, such as when iXr believes in good faith that the law requires it or as permitted in terms of this policy.</li>
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' /> You are required to submit your information at the time of making an online purchase on the platforms. iXr uses online payment gateways that are operated by either RazorPay, Stripe, or PayPal and the information that you share with iXr is transferred and shared with such third-party payment gateway operators. Privacy Policy of RazorPay, Stripe, and PayPal are available here, here and here. The payment gateways may also have access to your online purchase history/details that you make from the platforms. Extremely sensitive information, like your credit card or debit card details, are transacted upon secure sites of approved payment gateways which are digitally under encryption, thereby providing the highest possible degree of care as per current technology. You are advised, however, to exercise discretion on usage.
                        </li>
                    </ul>
                    <h3 className="text-light text-shadow1 mt-5">5. Redirection to Third-Party Sites</h3>
                    <p className='text-white'>
                        Links to third-party sites are provided by the platform as a convenience to user(s) and iXr does not have any control over such sites i.e. content and resources provided by them. iXr may allow user(s) access to content, products, or services offered by third-parties through hyperlinks (in the form of word link, banners, channels or otherwise) to such third-party’s website. You are cautioned to read such sites’ terms and conditions and/or privacy policies before using such sites in order to be aware of the terms and conditions of your use of such sites. You acknowledge and agree that iXr has no control over such third-party’s site, does not monitor such sites, and iXr shall not be responsible or liable to anyone for such third-party site, or any content, products, or services made available on such a site.
                    </p>
                    <h3 className="text-light text-shadow1 mt-5">6. Protection of Information</h3>
                    <ul class="list-group">
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' />iXr takes stringent steps, within its limits of commercial viability and necessity, to ensure that the user’s information is always treated securely.</li>
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' />  We request our users to sign out of their iXr account and close their browser window when they have finished their work. This is to ensure that others cannot access their personal or business information and correspondence if the user shares the computer with someone else or is using a computer in a public place.
                        </li>
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' />  Unfortunately, no data transmission over the Internet can be guaranteed to be 100% secure. As a result, while iXr strives to protect the user’s personal & business information, it cannot ensure the security of any information transmitted to iXr and you do so at your own risk. Once iXr receives your transmission, it makes best efforts to ensure its security in its systems. Please keep in mind that whenever you post personal & business information online that is accessible to the public, you may receive unsolicited messages from other parties.
                        </li>
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' />iXr is not responsible for any breach of security or for any actions of any third parties that receive your Information. The platform is also linked to many other sites and we are not/shall not be responsible for their privacy policies or practices as it is beyond our control.
                        </li>
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' />Notwithstanding anything contained in this Policy or elsewhere, iXr shall not be held responsible for any loss, damage, or misuse of your Information, if such loss, damage or misuse is attributable to a Force Majeure Event (as defined in Terms of Use).
                        </li>
                    </ul>
                    <h3 className="text-light text-shadow1 mt-5">7. Communication Through Information</h3>
                    <p className='text-white'>
                        iXr may, from time to time, send its users emails and instant messages, through designated platforms, regarding its products and services. iXr constantly tries and improves the platform for better efficiency, more relevancy, innovative business matchmaking, and better personal preferences. iXr may allow direct mails using its own scripts (without disclosing the email address) with respect/pertaining to the products and services of third-parties that it feels may be of interest to the user or if the user has shown interest in the same.
                    </p>
                    <h3 className="text-light text-shadow1 mt-5">8. Amendment</h3>
                    <p className='text-white'>
                        The policy may be amended from time to time so please check periodically. The revised Policy shall be made available on the platforms. Your continued use of the platforms, following changes to the Policy, will constitute your acceptance of those changes. Any disputes arising under this Policy shall be governed by the laws of India.
                    </p>
                    <h3 className="text-light text-shadow1 mt-5">9. Grievance Redressal</h3>
                    <ul class="list-group">
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' /> iXr has a designated grievance officer in compliance with Rule 5(9) of the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011.</li>
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' /> In case of any grievance regarding the use of your Information, please write to the following contact with all relevant details of your grievance: <br />
                            Email: <Link to='mailto:hrexec@interioxr.com' className='text-primary'>hrexec@interioxr.com</Link> <br />
                            Galleria Market, DLF Phase IV, Gurugram, Haryana, India
                        </li>
                    </ul>
                    <h3 className="text-light text-shadow1 mt-5">10. Applicable Law</h3>
                    <ul class="list-group mb-5">
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' /> Your use of this platform will be governed by and construed in accordance with the laws of India.</li>
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' /> You agree that any legal action or proceedings arising out of your use may be brought exclusively before the competent courts/fora/authorities having jurisdiction in New Delhi, India and irrevocably submit yourself to the jurisdiction of such courts/forums/authorities.
                        </li>
                    </ul>
                    <h6 className='text-center fst-italic text-white'>InterioXr Technologies Private Limited</h6>
                    <h6 className='text-center fst-italic text-white'>CIN: U72900HR2022PTC101465</h6>
                    <h6 className='text-center fst-italic text-white'>Galleria Market, DLF Phase IV, Gurugram</h6>
                    <h6 className='text-center fst-italic text-white'>
                        <Link to='tel: +919560013324' className='text-primary'> +91 9560013324 </Link>,
                        <Link to='mailto: info@interioxr.com' className='text-primary'> info@interioxr.com</Link>
                    </h6>
                </div>
            </section>
        </>
    )
}

export default Privacy