import React, { useEffect } from 'react';
import { ChevronsRight, ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';

const Term = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <section className='term py-5' style={{ 'backgroundColor': '#0b1321' }}>
                <div className="container">
                    <h1 className="text-light text-shadow1 mt-5 mb-2 text-center">TERMS OF SERVICES & USE</h1>
                    <p className='text-light text-center mb-3'>Updated as on 12th January 2024</p>
                    <div className="butt text-center">
                        <Link className='btn btn-primary rounded-pill shadow-lg mb-5 ' to='https://docs.google.com/document/d/e/2PACX-1vQ_7JUsL9-mFVyGBqzq5oh7H3UzMMgREcxUcJVaLlfb_T-68Q5OKQC6sHvcrWAdiX23A_Yxs3C6Yg6f/pub' target='_blank'>View Google Docs</Link>
                    </div>
                    <p className='text-white'>Please read these conditions carefully before placing an order for any products or services with the Vendors registered with InterioXr Technologies Private Limited (hereinafter referred to as “iXr”) and its platform InteriorVerse (hereinafter referred to as “iVr”) through the InterioXr App & Web platform consisting of its software & hardware solutions (hereinafter referred to as “App & Web platform” or “Platform” or “Us”). Wherever the business entity iXr is mentioned within the text, it is of safe assumption that the terms extend to the iVr platform as its web & app offering. These conditions signify the customer’s (hereinafter referred to as “You”) agreement to be bound by these conditions.
                    </p>
                    <p className='text-white'>In addition, when you buy any product or use any current or future iXr service (e.g.: Account, Cart, Wishlist, Professional Services Page, Special Requests or Product Marketplace), you will also be subject to these terms, guidelines, and conditions as applicable (hereinafter referred to as “Terms”).
                    </p>
                    <h3 className="text-light text-shadow1 mt-5">1. Conditions Relating to Sale</h3>
                    <p className='text-white'>
                        These terms deal with conditions relating to the sale of (digital or physical) products and services on the platform by the Vendors, or by Us, to you. You agree to these terms for each transaction made by you, or your authorised representatives.
                    </p>
                    <h3 className="text-light text-shadow1 mt-5">2. Our Contract</h3>
                    <ul class="list-group">
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' />Your order is an invitation to offer to Us and the Vendors through Us & the platform to buy the product/service from Us or the Vendor to you. When you place an order through Us to purchase a product/service from Us or our Vendors, you shall receive an email confirming receipt of your order and details of your order (hereinafter referred to as “Order Confirmation Email”). The Order Confirmation Email is acknowledgement that we have received your order and does not confirm acceptance of your offer to buy the product/service ordered. We accept your offer through our platform directly, or by our Vendors, and conclude the contract of sale for a product/service ordered by you, when the product/service is dispatched to you by the Vendor, after negotiations on the final price and amount if applicable and confirmation of the final price and amount and payment towards the product/service by you, post which an email confirmation is sent to you that the product has been dispatched to you by the Us or the Vendor (hereinafter referred to as "Dispatch Confirmation Email"). If your order is dispatched in more than one package, you may receive a separate Dispatch Confirmation Emails for each package, and each Dispatch Confirmation Email and corresponding dispatch will conclude a separate contract of sale between you and our Vendors for the product/service specified in that Dispatch Confirmation Email.</li>
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' /> Your contract is with Us & the Vendors, and we are both the business entity and the platform to this extent, as concluded by your service providers – either Us or the Vendors. You confirm that the product/service ordered by you is in the capacity to contract. You authorise Us, as well as the platform, to declare and provide declaration to any governmental authority on your behalf stating the aforesaid purpose of the products ordered by you on the platform.
                        </li>
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' /> You can cancel your order for a product at no cost any time before the platform sends the Dispatch Confirmation Email relating to that product/service.
                        </li>
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' />Your confirmations on the platform, secured by adequate authentications, count as your final agreements towards the different stages of eCommerce prospects.
                        </li>
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' />For the avoidance of doubt, it is clarified that any additional by-product or add-on service will constitute a separate contract between you and Us, or our Vendors. It is further clarified that you will hold iXr harmless in the event of any discrepant act or omission on our part.
                        </li>
                    </ul>
                    <h3 className="text-light text-shadow1 mt-5">3. Replacement of Digital Products/Services</h3>
                    <ul class="list-group">
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' />The digital products/services purchased from Us or the Vendors listed on the platform are not replaceable. However, Edits can be made within those products, carrying different Add-on prices, such that iXr delivers You with an ‘enhanced’ version of the product/service. By rule of logic, any edit/change that fundamentally changes the product/service delivered, shall be counted as a separate product/service.</li>
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' /> Most physical products purchased from us are replaceable within the replacement window of 7 business days, except those that are explicitly identified as non-replaceable. The replacement is processed only if:
                            <ul class="list-group">
                                <li class="list-group-item bg-transparent text-white border-0"><ChevronRight className='mb-1' /> it is determined that the product was not damaged while in your possession;</li>
                                <li class="list-group-item bg-transparent text-white border-0"><ChevronRight className='mb-1' /> the product is not different from what was shipped to you;
                                </li>
                                <li class="list-group-item bg-transparent text-white border-0"><ChevronRight className='mb-1' /> the product is returned in original condition (with brand’s/manufacturer's box, MRP tag intact, user manual, warranty card and accessories);
                                </li>
                                <li class="list-group-item bg-transparent text-white border-0"><ChevronRight className='mb-1' />  for the products that are not replaceable or returned by the customer for any material defect without any stock availability, the refund is issued to the original payment method made by you; the timeline for receipt of such refund is solely dependent on your bank, which is usually 7-14 business days.
                                </li>
                            </ul>
                        </li>
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' /> Refund is subject to the discretion of the Company in cases where one or all the conditions under Clause 3.1 above is not met, in which case such refund may be alternated with discounts and other coupon codes and offers as per the arrangement between You & iXr.
                        </li>
                    </ul>
                    <h3 className="text-light text-shadow1 mt-5">4. Pricing and Availability</h3>
                    <ul class="list-group">
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' />We list tentative availability information for products sold and services provided by Us & our Vendors on the platforms, including on each product information/service profile page. Beyond what we say on these pages or otherwise on the platforms, we cannot be more specific about availability. More clarification shall be provided to the User by iXr, once asked. Please note that dispatch estimates are just that. They are not guaranteed dispatch times and should not be relied upon as such. As we process your order, emailed, SMS, or any instant messaging medium shall be used for any communications regarding the products/services.
                        </li>
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' /> All prices are inclusive of VAT/CST, service tax, GST, duties, and cesses as applicable - unless stated otherwise.
                        </li>
                    </ul>
                    <h3 className="text-light text-shadow1 mt-5">5. Taxes</h3>
                    <p className='text-white'>
                        You shall be responsible for payment of all fees/costs/charges associated with the purchase of products or services from our Vendors through Us and you agree to bear any and all applicable taxes including but not limited to VAT/CST, service tax, GST, duties, and cesses etc.
                    </p>
                    <h3 className="text-light text-shadow1 mt-5">6. Your Account</h3>
                    <p className='text-white'>
                        By creating an account with Us and usage of our platform, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer to prevent unauthorised access to Your Account. You agree to accept responsibility for all activities that occur under your account or password. You should take all necessary steps to ensure that the password is kept confidential and secure and should inform the platform immediately if you have any reason to believe that your password has become known to anyone else, or if the password is being, or is likely to be, used in an unauthorised manner. Please ensure that the details you provide the platform with are correct and complete and inform the platform immediately of any changes to the information that you provided when registering. You can access and update much of the information you provided Us with in the Your Account area of the platform. iXr reserves the right to refuse access to the platform, terminate Your Account, remove, or edit content at any time without notice to you.
                    </p>
                    <h3 className="text-light text-shadow1 mt-5">7. Privacy</h3>
                    <p className='text-white'>
                        Please review the Privacy Policy available here, which also governs your visit to the platform. The personal information / data provided to the platform by you during the course of usage of iXr shall be treated as strictly confidential and in accordance with the Privacy Policy and applicable laws and regulations. If you object to your information being transferred or used, please do not use the platform.
                    </p>
                    <h3 className="text-light text-shadow1 mt-5">8. Pricing</h3>
                    <p className='text-white'>
                        The pricing of all products and services on the iXr platform would be mentioned on the platform itself. The products/services rendered by iXr shall be governed from the company’s pricing model. Similarly, the products/services rendered by the Vendors shall be derived as per the vendors’ and service providers’ pricing model. iXr will charge a platform fee/convenience fee, shown in a transparent manner, added separately to the product/service listing wherever applicable.
                    </p>
                    <h3 className="text-light text-shadow1 mt-5">9. Refunds, Returns, and Cancellations</h3>
                    <ul class="list-group">
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' /> With respect to the physical materials displayed on the platform, any refund and/or return would depend upon iXr, or the vendor’s ability and consent to accept a refund/return. The platform would only facilitate the communication of the same.</li>
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' />  If you wish to, you can cancel your order for a product at no cost any time before the platform sends the Dispatch Confirmation Email relating to that product/service. Once the platform dispatches your order, the actual delivery of the product/service, including its refund/return would wholly be dependent on the vendor’s ability to do so, wherein the Platform would only facilitate the cancellation of such services if requested by You/the User. The communication of the same would be the Platform’s responsibility, as well as the Vendor’s.
                        </li>
                    </ul>
                    <h3 className="text-light text-shadow1 mt-5">10. Shipping Policy</h3>
                    <p className='text-white'>
                        The Platform uses a range of third-party hyper-local, inter-state, and inter-continental delivery services to facilitate the delivery of the purchased products to the User. Once the item has been shipped to the delivery party, the delivery party has the highest responsibility to ensure the safe communication and delivery of the said items. In this scenario, iXr as a platform, can only provide communication support of the said deliveries.
                    </p>
                    <h3 className="text-light text-shadow1 mt-5">11. Access to iXr platform</h3>
                    <p className='text-white'>
                        The platform will do its utmost to ensure that availability of the platform will be uninterrupted and that transmissions will be error-free. However, due to the inherent and erratic nature of the Internet, this cannot be guaranteed. Also, your access to the platform may also be occasionally suspended or restricted to allow for repairs, maintenance, or the introduction of new facilities or services at any time without prior notice. iXr will attempt to limit the frequency and duration of any such suspension or restriction.
                    </p>
                    <h3 className="text-light text-shadow1 mt-5">12. License</h3>
                    <ul class="list-group">
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' /> Subject to your compliance with these Terms of Service and Use and payment of applicable fees, if any, iXr grants you a limited licence to access and make personal use of this platform, but not to download (other than page caching) or modify it, or any portion of it, except with express written consent of iXr and / or its affiliates, as may be applicable.
                        </li>
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' />  The Extended Reality XR services are provided by iXr, along with its business partners, for viewing purposes and a limited use licence is to access and view only. You will not, either directly or indirectly, make personal use of this XR service, download (other than page caching) or modify it, or any portion of it, except with express written consent of iXr and / or its affiliates, as may be applicable, which will be in the nature of monetary payment alone. Usage, access, and display of such services by our Vendors are subject to the terms of the Vendor Agreement between us and iXr.
                        </li>
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' />  THIS LICENCE DOES NOT INCLUDE ANY RESALE OR COMMERCIAL USE OF THIS PLATFORM OR ITS CONTENTS; ANY COLLECTION AND USE OF ANY PRODUCT OR SERVICE PROFESSIONAL LISTINGS, DESCRIPTIONS, OR PRICES; ANY DERIVATIVE USE OF THIS PLATFORM OR ITS CONTENTS; ANY DOWNLOADING OR COPYING OF ACCOUNT INFORMATION FOR THE BENEFIT OF ANOTHER SELLER; OR ANY USE OF DATA MINING, ROBOTS, OR SIMILAR DATA GATHERING AND EXTRACTION TOOLS.
                        </li>
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' /> This platform or any portion of this platform (including but not limited to any copyrighted material, trademarks, or other proprietary information) may not be reproduced, duplicated, copied, sold, resold, visited, distributed, or otherwise exploited for any commercial purpose without express written consent of iXr and / or its affiliates, as may be applicable.
                        </li>
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' /> You may not frame or use framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of iXr, its Vendors and its affiliates without express written consent. You may not use any meta tags or any other "hidden text" utilising iXr’s or its affiliates' names or trademarks without the express written consent of iXr and / or its affiliates, as applicable. Any unauthorised use terminates the permission or licence granted by iXr and / or its affiliates, as applicable.
                        </li>
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' />You are granted a limited, revocable, and non-exclusive right to create a hyperlink to the Welcome page of iXr as long as the link does not portray iXr, its projects, their affiliates, or their products or services in a false, misleading, derogatory, or otherwise offensive matter. You may not use any iXr logo or other proprietary graphic or trademark as part of the link without express written consent of iXr and / or its affiliates, as may be applicable.
                        </li>
                    </ul>
                    <h3 className="text-light text-shadow1 mt-5">13. Your Conduct</h3>
                    <ul class="list-group">
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' /> You must not use the platform in any way that causes, or is likely to cause, the platform or access to it to be interrupted, damaged or impaired in any way. You understand that you, and not iXr, are responsible for all electronic communications and content sent from your computer to the platform and you must use the platform for lawful purposes only. You must not use the platform for any of the following:
                            <ul class="list-group">
                                <li class="list-group-item bg-transparent text-white border-0"><ChevronRight className='mb-1' />  for fraudulent purposes, or in connection with a criminal offence or other unlawful activity.</li>
                                <li class="list-group-item bg-transparent text-white border-0"><ChevronRight className='mb-1' />  to send, use or reuse any material that does not belong to you; or is illegal, offensive (including but not limited to material that is sexually explicit content or which promotes racism, bigotry, hatred or physical harm), deceptive, misleading, abusive, indecent, insulting or harassing, blasphemous, defamatory, libellous, obscene, pornographic, paedophilic, or menacing; ethnically objectionable, disparaging or in breach of copyright, trademark, patent, confidentiality, privacy or any other proprietary information or right; or is otherwise injurious to third parties; or relates to or promotes money laundering or gambling; or is harmful to minors in any way; or impersonates another person; or threatens the unity, integrity, defence, security or sovereignty of India or friendly relations with foreign States or public order or causes incitement to the commission of any cognisable offence or prevents investigation of any offence or is insulting other nation; or objectionable or otherwise unlawful in any manner whatsoever; or which consists of or contains software viruses or any other computer code, file or program designed to interrupt, destroy or limit the functionality of any computer resource, political campaigning, commercial solicitation, chain letters, mass mailings or any "spam; or is patently false and untrue.
                                </li>
                                <li class="list-group-item bg-transparent text-white border-0"><ChevronRight className='mb-1' /> to cause annoyance, inconvenience, or needless anxiety.
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <h3 className="text-light text-shadow1 mt-5">14. Indemnity and Release</h3>
                    <ul class="list-group">
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' /> You shall indemnify and hold harmless iXr, its subsidiaries, affiliates and their respective officers, directors, agents and employees, from any claim or demand, or actions including reasonable advocate's fees, made by any Vendor or penalty imposed due to or arising out of your breach of these Terms of Service and Use or any document incorporated by reference, or your violation of any law, rules, regulations or the rights of a third party.
                        </li>
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' /> You hereby expressly release iXr and/or its affiliates and/or any of its officers and representatives from any cost, damage, liability, or other consequence of any of the actions/inactions of the Vendors and specifically waiver any claims or demands that you may have in this behalf under any statute, contract or otherwise.
                        </li>
                    </ul>
                    <h3 className="text-light text-shadow1 mt-5">15. Claims against Objectionable Content</h3>
                    <ul class="list-group">
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' />You can refer to the product detail / service professional page on the platform for checking any product details / service specifications provided by the Vendor regarding the following:
                            <ul class="list-group">
                                <li class="list-group-item bg-transparent text-white border-0"><ChevronRight className='mb-1' /> the total price in single figure of any product or service, along with the breakup price for the good or service, showing all the compulsory and voluntary charges such as delivery charges, postage and handling charges, conveyance charges and the applicable tax, as applicable. These details are available on the invoice issued to you. Further, all contractual information required to be disclosed by law is incorporated as part of the iXr Vendor Agreement executed by each seller/vendor/service professional/business on iXr platform, prior to listing its products and services.
                                </li>
                            </ul>
                            <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' />  Because iXr lists multiple products for sale offered by them, and by their Vendors on the platform; hosts many thousands of comments and subpages, it is not possible for iXr to be aware of the contents of each product/service listed for sale, or each comment that is displayed. Accordingly, iXr operates on a "notice and takedown" basis. If you believe that any content on the platform is illegal, offensive (including but not limited to material that is sexually explicit content or which promotes racism, bigotry, hatred or physical harm), deceptive, misleading, abusive, indecent, insulting or harassing, blasphemous, defamatory, libellous, obscene, pornographic, paedophilic, invasive of another’s privacy or menacing; ethnically objectionable, disparaging; or in breach of a third party’s confidential, proprietary information or right; or is otherwise injurious to third parties; or relates to or promotes money laundering or gambling; or is harmful to minors in any way; or impersonates another person; or threatens the unity, integrity, defence, security or sovereignty of India or friendly relations with foreign States, or public order, or causes incitement to the commission of any cognisable offence or prevents investigation of any offence or in insulting other nation; or objectionable or otherwise unlawful in any manner whatsoever; or which consists of or contains software viruses or any other computer code, file or program designed to interrupt, destroy or limit the functionality of any computer resource; or is patently false and untrue ("Objectionable Content"), please notify the platform immediately. Once this procedure has been followed, iXr will make all reasonable endeavours to remove such Objectionable Content complained about within a reasonable time, with an opportunity to the offender to explain its version, as per the law.
                            </li>
                        </li>
                    </ul>
                    <h3 className="text-light text-shadow1 mt-5">16. Copyright, authors' rights, and database rights</h3>
                    <ul class="list-group">
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' />All content included on the platform, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of iXr, its affiliates or its Vendors or content suppliers and is protected by India and international copyright, authors' rights, and database right laws. The compilation of all content on the platform is the exclusive property of iXr and its affiliates and is protected by laws of India and international copyright and database right laws. All software and ARVR Services used on this platform is the property of iXr, its affiliates or its software suppliers and is protected by India and international copyright and author' rights laws.
                        </li>
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' />You may not systematically extract / or re-utilise parts of the contents of the platform without iXr’s or its affiliate's (as may be applicable) express written consent. You may not utilise any data mining, robots, or similar data gathering and extraction tools to extract (whether once or many times) for re-utilisation of any substantial parts of this platform, without iXr and / or its affiliate's (as may be applicable) express written consent. You may also not create and/ or publish your own database that features substantial (eg: prices and product listings) parts of this platform without iXr and / or its affiliate's (as may be applicable) express written consent.
                        </li>
                    </ul>
                    <h3 className="text-light text-shadow1 mt-5">17. Intellectual Property Claims</h3>
                    <p class=" text-white"><ChevronsRight className='mb-1' /> iXr and its affiliates respect the intellectual property of others. If you believe that your intellectual property rights have been used in a way that gives rise to concerns of infringement, please notify Us immediately.
                    </p>
                    <h3 className="text-light text-shadow1 mt-5">18. Trademarks</h3>
                    <p class="text-white"><ChevronsRight className='mb-1' />iXr’s, including its projects’s logos, and other marks indicated on the platform are trademarks or registered trademarks of iXr or its subsidiaries (collectively "InterioXr Technologies") or our Vendors, in worldwide jurisdictions. iXr's graphics, logos, page headers, button icons, scripts and service names are the trademarks or trade dress of iXr. iXr’s trademarks and trade dress may not be used in connection with any product or service that is not iXr’s, in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits iXr. All other trademarks not owned by iXr that appear on the platform are the property of their respective owners and our Vendors, who may or may not be affiliated with, connected to, or sponsored by iXr.
                    </p>
                    <h3 className="text-light text-shadow1 mt-5">19. Communications</h3>
                    <ul class="list-group">
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' />When you visit the platforms, you are communicating with Us electronically, through the platform. Communication between our Vendors and You will never be direct and will always be through the platform, who is the intermediary for all transactions. You will be required to provide a valid phone number and/or email ID while placing an order with us. We may communicate with you by email, SMS, phone call or by posting notices on the platform or by any other mode of communication. For contractual purposes, you consent to receive communications including SMS, e-mails, or phone calls from Us with respect to your order.
                        </li>
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' />You agree, understand, and acknowledge that the platform is an online entity that enables you to purchase products and services listed on the platform at the price indicated therein at any time from any location. Accordingly, You agree and understand that any contract of sale of products and services through the platform shall be a strictly bipartite contract directly between You and the Vendors, or between You and Us.
                        </li>
                    </ul>
                    <h3 className="text-light text-shadow1 mt-5">20. Losses</h3>
                    <p class=" text-white"><ChevronsRight className='mb-1' /> Our Vendors, as well as the platform, will not be responsible for any business loss (including loss of profits, revenue, contracts, anticipated savings, data, goodwill or wasted expenditure) or any other indirect or consequential loss for any reason whatsoever when a contract for the sale of products or services by the Vendors to You was formed.
                    </p>
                    <h3 className="text-light text-shadow1 mt-5">21. Alteration or Amendments to the Conditions</h3>
                    <p class=" text-white"><ChevronsRight className='mb-1' /> We reserve the right to make changes to our policies, and these Conditions of Sale at any time. You will be subject to the policies and Conditions of Sale in force at the time you order products or services from our Vendors through the platform, unless any change to those policies or these conditions is required to be made by law or government authority (in which case it will apply to orders previously placed by you, if so directed). If any of these conditions is deemed invalid, void, or for any reason unenforceable, that condition will be deemed severable and will not affect the validity and enforceability of any remaining condition.
                    </p>
                    <h3 className="text-light text-shadow1 mt-5">22.  Events beyond our Reasonable Control</h3>
                    <p class=" text-white"><ChevronsRight className='mb-1' /> We will not be held responsible for any delay or failure to comply with our obligations under these conditions if the delay or failure arises from any cause or reason which is beyond our reasonable control.
                    </p>
                    <h3 className="text-light text-shadow1 mt-5">23.  Waiver</h3>
                    <p class=" text-white"><ChevronsRight className='mb-1' />  If you breach these conditions and we take no action, we will still be entitled to use our rights and remedies in any other situation where you breach these conditions.
                    </p>
                    <h3 className="text-light text-shadow1 mt-5">24. Disclaimer</h3>
                    <ul class="list-group">
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' />You acknowledge and undertake that you are accessing the products and services on the platform and transacting at your own risk and are using your best and prudent judgement before entering into any transactions with the Vendors through the platform. The platform shall neither be liable nor responsible for any actions or inactions of vendors nor any breach of conditions, representations or warranties by the Vendors of the products or services and hereby expressly disclaim any all responsibility and liability in that regard. The platform may mediate or resolve any dispute or disagreement between you and the Vendors of the products or services to the extent possible, subject to the good-faith relations between you, the Vendors and the platform.
                        </li>
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' /> iXr further expressly disclaim any warranties or representations (express or implied) in respect of quality, suitability, accuracy, reliability, completeness, timeliness, performance, safety, merchantability, fitness for a particular purpose, or legality of the products or services listed or displayed or transacted or the content (including product or pricing information and/or specifications) on the platform. iXr may implicitly or explicitly support or endorse the sale or purchase of any products or services on the platform as fairly as possible. At no time shall any right, title or interest in the products/services sold through or displayed on the platform vest with Us nor shall the platform have any obligations or liabilities in respect of any transactions on the platform.
                        </li>
                    </ul>
                    <h3 className="text-light text-shadow1 mt-5">25. Vendors and Other Businesses</h3>
                    <p class=" text-white"><ChevronsRight className='mb-1' />  Vendors, who are neither iXr nor its affiliates operate stores, provide services, or sell products on the platform (e.g.: Businesses offer products via the Product Marketplace and individuals/firms offer services via the Professional Services Page). The platform is not responsible for examining or evaluating, and the platform does not warrant or endorse the offerings of any of these businesses or individuals/firms, or the content of their respective listings. iXr does not assume any responsibility or liability for the actions, products, and content of any of these and any other third-parties. Terms and conditions between the Vendors and Us are available here.
                    </p>
                    <h3 className="text-light text-shadow1 mt-5">26. Governing Law and Jurisdiction</h3>
                    <ul class="list-group">
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' />Your use of this platform will be governed by and construed in accordance with the laws of India, regardless of your worldwide location.
                        </li>
                        <li class="list-group-item bg-transparent text-white border-0"><ChevronsRight className='mb-1' />  You agree that any legal action or proceedings arising out of your use may be brought exclusively before the competent courts/fora/authorities having jurisdiction in New Delhi, India and irrevocably submit yourself to the jurisdiction of such courts/fora/authorities.
                        </li>
                    </ul>
                    <h3 className="text-light text-shadow1 mt-5">27. Platform Details</h3>
                    <p class=" text-white mb-5"><ChevronsRight className='mb-1' /> The iXr website is operated as a landing page for all its App & Web products and services available on the platform where registered Vendors and You take part in voluntarily. For anything else relative to iXr, its projects, and the platform, you could contact us by visiting interioxr.com
                    </p>
                    <h6 className='text-center fst-italic text-white'>InterioXr Technologies Private Limited</h6>
                    <h6 className='text-center fst-italic text-white'>CIN: U72900HR2022PTC101465</h6>
                    <h6 className='text-center fst-italic text-white'>Galleria Market, DLF Phase IV, Gurugram</h6>
                    <h6 className='text-center fst-italic text-white'>
                        <Link to='tel: +919560013324' className='text-primary'> +91 9560013324 </Link>,
                        <Link to='mailto: info@interioxr.com' className='text-primary'> info@interioxr.com</Link>
                    </h6>
                </div>
            </section>
        </>
    )
}

export default Term