import React, { useState } from 'react';
import '../App.css';
import { NavLink, Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Home, Phone, Rocket } from 'lucide-react';

const Head = () => {

    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMobileMenuOpen((prev) => !prev);
    };

    return (
        <>
            <header>
                <div className="menu__bar" style={{'marginBottom' : '10px'}}>
                    <a href="/" alt="Logo" className="logo">
                        <img src={`assets/Logo.webp`} alt="Logo" className='img-fluid' />
                    </a>
                    <img
                        className="menu-icon"
                        src={isMobileMenuOpen ? `assets/close.svg` : `assets/burger-menu.svg`}
                        title="Burger Menu"
                        alt="Burger Menu"
                        onClick={toggleMenu}
                    />
                    <ul className={`navigation ${isMobileMenuOpen ? 'navigation--mobile' : ''}`} style={{ marginBottom: '0' }}>
                        <li>
                            <NavLink exact to='/'>
                                <Home className='mb-1' /> Home
                            </NavLink>
                        </li>
                        <li>
                            <Link to='https://interiorverse.interioxr.com/' target="_blank">
                                <Rocket className='mb-1' /> InteriorVerse
                            </Link>
                        </li>
                        <li>
                            <NavLink to='/contact'>
                                <Phone className='mb-1' />  Contact Us
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </header>
        </>
    )
}

export default Head