import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.bundle.min";
import '../App.css';

const ScrollSection = () => {

    return (
        <>
            <div className='sec-2'>
                <div className="container" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-out-cubic">
                    <div className="row align-items-center">
                        <div className="col-lg-8 col-md-6 col-sm-12 my-4">
                            <h1 className="text-light text-shadow1 mb-4">THE STORY <br /> BEHIND INTERIOXR</h1>
                            <p className='text-light fs-5 mb-4'>We’ve always sought to bring the latent & potent power of Extended Reality - Virtual, Augmented, and Mixed - to the current spectrum of technologies that are being used around the world.</p>
                            <a className='btn btn-primary fs-5 rounded-pill shadow-lg mb-5' href='/'>Explore iXr Projects</a>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 my-4">
                            <div class="card p-3 rounded-5 bg-transparent border border-muted" >
                                <video autoPlay loop muted className='rounded-5 mb-3' >
                                    <source src={`assets/file.mp4`} type="video/mp4" />
                                </video>
                                <div class="card-body">
                                    <h5 class="card-title text-light">interiorVerse</h5>
                                    <p class="card-text text-secondary">Through our flagship InteriorVerse app, you can now upload your room designs, any room, and literally walk around in it...</p>
                                    <a href="https://interiorverse.interioxr.com/webapp" target='_blank' rel="noreferrer" class="btn bg-transparent rounded-pill shadow-lg border-secondary text-light">Jump In!</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 my-4">
                            <img src={`assets/Branding.webp`} alt="family logo" className='img-fluid' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 my-4">
                            <h1 className="text-light text-shadow1 mb-4">THE INTERIOXR <br /> INFINITY STONES</h1>
                            <p className='text-light fs-5 mb-4'>We believe that there are more dimensions of work, play, and exploration that can be accessed by extended visions calling for a level up to the game that we play at present.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ScrollSection