import React, { useState } from 'react';
import '../App.css';
import { MailCheck, MapPinned, Phone, ChevronsRight, Facebook, Twitter, Instagram, Linkedin } from 'lucide-react';
import { Link } from 'react-router-dom';
import { FaThreads } from "react-icons/fa6";
import axios from 'axios';
const Foot = () => {

    const [email, setEmail] = useState("");

    const handleSubmit = (e) => {
        setEmail(e.target.value)
    }

    const enquiryurl = `https://admin.interioxr.com`;

    const handleApi = async () => {
        const url = enquiryurl || 'http://localhost:3001'; 
        console.log('URL:', `${url}/api/v1/enquiry`);
        await axios.post(`${url}/api/v1/enquiry`, {
            "email": email,
            "source": "interioxr.com:Footer"
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(result => {
                alert("Thank you for your interest. We will reach out to you shortly.");
                console.log(result);
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <>
            <footer className='foot py-5'>
                <div className="container" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-out-cubic">
                    <div className="row align-items-center">
                        <div className="col-12 text-center">
                            <h1 className='text-light text-shadow1'>SUBSCRIBE TO OUR SHENANIGANS</h1>
                            <h4 className='text-white mb-4'>Be the first to know about new features, exclusive previews & special promotions
                            </h4>
                            <input type="email" name="email" value={email} onChange={handleSubmit} placeholder='Email Address' className="form-control mb-4 bg-transparent w-50 m-auto shadow-lg rounded-pill text-white" id="" />
                            <p className='btn btn-primary fs-5 rounded-pill shadow-lg px-5 py-2 mb-5' onClick={handleApi} >Submit</p>
                            <hr className='text-light shadow-lg' />
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-lg-3 col-md-6 col-sm-12 my-3">
                            <p className='text-white'>Regenerating scattered realities through cutting-edge solutions connecting existing 2D business processes with 3D interfaces & welcoming businesses to a new tomorrow</p>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 my-3">
                            <h4 className='text-light text-shadow1'>Contact</h4>
                            <ul class="list-group">
                                <li class="list-group-item bg-transparent border-0 py-1 text-white px-1"><MapPinned className='mb-1' /> DLF Phase IV, Gurugram
                                </li>
                                <li class="list-group-item bg-transparent border-0 py-1 text-white px-1"><MailCheck /> <Link to="mailto:info@interioxr.com" className='text-white'>info@interioxr.com</Link></li>
                                <li class="list-group-item bg-transparent border-0 text-white px-1"><Phone /> <Link to="tel:+919560013324" className='text-white'>+91 9560013324</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6 col-6 my-3">
                            <h4 className='text-light text-shadow1'>Quick Menu</h4>
                            <ul class="list-group">
                                <li class="list-group-item bg-transparent border-0 py-1 text-white px-1"><Link to="/projects" className='text-white'><ChevronsRight className='mb-1' /> Projects</Link></li>
                                <li class="list-group-item bg-transparent border-0 py-1 text-white px-1"><Link to="/" className='text-white'><ChevronsRight className='mb-1' /> About</Link></li>
                                <li class="list-group-item bg-transparent border-0 py-1 text-white px-1"><Link to="/privacy " className='text-white'><ChevronsRight className='mb-1' /> Privacy Policy</Link></li>
                                <li class="list-group-item bg-transparent border-0 py-1 text-white px-1"><Link to="/terms" className='text-white'><ChevronsRight className='mb-1' /> Terms of Use</Link></li>
                                <li class="list-group-item bg-transparent border-0 py-1 text-white px-1"><Link to="/contact" className='text-white'><ChevronsRight className='mb-1' /> Contact</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6 col-6 my-3">
                            <h4 className='text-light text-shadow1'>Social</h4>
                            <ul class="list-group">
                                <li class="list-group-item bg-transparent border-0 py-1 text-white px-1"><Link to="https://www.facebook.com/interioxr" className='text-white'><Facebook className='mb-1' /> Facebook</Link></li>
                                <li class="list-group-item bg-transparent border-0 py-1 text-white px-1"><Link to="https://twitter.com/InterioXr" className='text-white'><Twitter className='mb-1' /> Twitter</Link></li>
                                <li class="list-group-item bg-transparent border-0 py-1 text-white px-1"><Link to="https://www.instagram.com/interioxr/" className='text-white'><Instagram className='mb-1' /> Instagram</Link></li>
                                <li class="list-group-item bg-transparent border-0 py-1 text-white px-1"><Link to="https://www.threads.net/@interioxr" className='text-white'>< FaThreads className='fs-4' /> Thread</Link></li>
                                <li class="list-group-item bg-transparent border-0 py-1 text-white px-1"><Link to="https://www.linkedin.com/company/interioxr/" className='text-white'><Linkedin className='mb-1' /> LinkedIn</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-center">
                            <h1 className='text-light text-shadow1'>interioXr Technologies</h1>
                            <p className='text-light mb-0'>© 2023 by interioXR. All Rights Reserved</p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Foot