import React from 'react';
import { Link } from 'react-router-dom';

const ProjectSection = () => {

    let data = [
        { img: '1.mp4', bgimg: '1.webp', link: 'https://interiorverse.interioxr.com/',head: 'interiorVerse', title: 'Immersive Spatial Walkthroughs', subtitle: 'Alpha Build Now Live' },
        { img: '2.mp4', bgimg: '2.webp', link: '/txr',head: 'tXR', title: 'Breaking Down Linguistic Barriers', subtitle: 'Coming Soon' },
        { img: '3.mp4', bgimg: '3.webp', link: '/dinexr',head: 'dXr', title: 'Levelling Up The Dining Field', subtitle: 'Coming Soon' },
        { img: '4.mp4', bgimg: '4.webp', link: '/wxr',head: 'wXR', title: 'Revolutionizing Storefronts', subtitle: 'Coming Soon' },
        { img: '5.mp4', bgimg: '5.webp', link: '/drivexr',head: 'Motor HUD Dynamics', title: 'Rethinking Motor HUD Dynamics', subtitle: 'Coming Soon' },
        { img: '6.mp4', bgimg: '6.webp', link: '/pxr',head: 'AR Immersions', title: 'Rethinking AR Immersions', subtitle: 'Coming Soon' }
    ]

    let Datalist = data.map((val, i) => {
        const cardBodyStyle = {
            backgroundImage: `url('assets/CardBg/${val.bgimg}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            color: 'white',
        };
        return (
            <div className='col-lg-4 col-md-6 col-sm-12 my-4' key={i}>
                <Link to={`${val.link}`} target="_blank">
                    <div class="card text-center border-0 p-4 bg-transparent shadow-lg rounded-5">
                        <div className="card-body rounded-5" style={cardBodyStyle}>
                            <div className="image text-center mb-4" style={{ marginTop: '-40px' }}>
                                <video autoPlay loop muted className='rounded-5 img-fluid' >
                                    <source src={`assets/Logos/${val.img}`} type="video/mp4" />
                                </video>
                            </div>
                            <h5 className="card-title mb-4">{val.head}</h5>
                            <h6 className="card-subtitle mb-2">{val.title}</h6>
                            <p className="card-text">{val.subtitle}</p>
                        </div>
                    </div>
                </Link>
            </div>
        )
    })
    return (
        <>
            <h1 className="text-light text-shadow1 mb-5 text-center">OUR PROJECTS</h1>
            <div className="row">
                {Datalist}
            </div>
        </>
    )
}

export default ProjectSection