import React, { useEffect } from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.bundle.min";
import ScrollSection from '../components/ScrollSection';
import ProjectSection from '../components/ProjectSection';
import { Facebook, Instagram, Linkedin, Twitter } from 'lucide-react'
import { FaDiscord, FaWhatsapp } from "react-icons/fa";
import { Link } from 'react-router-dom';

const Home = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>

            {/* Section-1 */}
            <section className='sec-1'>
                <video autoPlay loop muted>
                    <source src={`assets/Home.mp4`} type="video/mp4" />
                </video>
                <div className="content">
                    <div className="text">
                        <h1 className="fs-40 w-5 c-secondary text-shadow">WE BRING YOUR EXTENDED REALITIES TO LIFE USING OUR EXTENDED REALITIES.
                        </h1>
                    </div>
                </div>
            </section >

            {/* Section-2 */}
            < ScrollSection />


            {/* Section-3 */}
            <section section className='sec-3 py-5' >
                <div className="container" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-out-cubic">
                    <ProjectSection />
                </div>
            </section >

            {/* Section-4 */}
            < section className='sec-4 py-5' >
                <div className="container rounded-5" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-out-cubic">
                    <div className="row align-items-center text-center">
                        <div className="col-lg-8 col-sm-12 my-3">
                            <video autoPlay loop muted className='rounded-5 w-100' >
                                <source src={`assets/file.mp4`} type="video/mp4" />
                            </video>
                        </div>
                        <div className="col-lg-4 col-sm-12 my-3">
                            <h1 className="text-light text-shadow1 mb-4 text-center">A CUTTING-EDGE BREAKTHROUGH</h1>
                            <p className='text-light mb-4'>We’ve found a way wherein you can enter and ‘walk around’ any virtual environment that comes into your mind.
                                Our team will enable you to build those interiors so you can live your dreams.
                                Hop on, jump in, dream on.</p>
                            <a className='btn btn-primary fs-5 rounded-pill shadow-lg' href='https://interiorverse.interioxr.com/webapp'>Explore Project interiorVerse</a>
                        </div>
                    </div>
                </div>
            </ section>

            {/* Section-5 */}
            < section className='sec-5 py-5' >
                <div className="container" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-out-cubic">
                    <div class="card bg-transparent rounded-5 border-0 shadow-lg">
                        <div class="card-body rounded-5 p-5">
                            <h1 class="card-title text-light text-shadow1 mb-4 w-5">WORK, PLAY, EXPLORE WITH US</h1>
                            <a className='btn btn-primary fs-5 rounded-pill shadow-lg' href='/'>Open Positions</a>
                        </div>
                    </div>
                </div>
            </ section>


            <Link to='https://www.facebook.com/interioxr'><button className='btn face fs-3 text-white'><Facebook /></button></Link>
            <Link to='https://api.whatsapp.com/send/?phone=918826144224&text&type=phone_number&app_absent=0' target='_blank'><button className='btn whats fs-4 text-white'><FaWhatsapp /></button></Link>
            <Link to='https://www.linkedin.com/company/interioxr/'><button className='btn link fs-3 text-white pb-3'><Linkedin /></button></Link>
            <Link to='https://www.instagram.com/interioxr/' target='_blank'><button className='btn insta fs-3 text-white pb-3'><Instagram /></button></Link>
            <Link to='https://twitter.com/InterioXr'><button className='btn twit fs-3 text-white pb-3'><Twitter /></button></Link>
            <Link to='https://discord.gg/z8h5BTUc' target='_blank'><button className='btn discord fs-3 text-white pb-3'><FaDiscord /></button></Link>
        </>
    )
}

export default Home