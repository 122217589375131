import React from 'react'

const Pxr = () => {
    return (
        <>
            {/* Section-Video */}
            <section>
                <video autoPlay loop muted className='rounded-5 img-fluid' >
                    <source src={`assets/logo-video/pxr.mp4`} type="video/mp4" />
                </video>
            </section>
        </>
    )
}

export default Pxr